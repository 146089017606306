import React from 'react';
import { Link } from 'gatsby';
import appData from 'data/app.json';
import { handleDropdown, handleMobileDropdown } from 'common/navbar';

const NavbarApp = ({ lr, nr, theme }) => {
  return (
    <nav
      ref={nr}
      className={`navbar navbar-expand-lg change ${
        theme === 'themeL' ? 'light' : ''
      }`}
    >
      <div className='container'>
        <Link to='/' className='logo'>
          {theme ? (
            theme === 'themeL' ? (
              <img ref={lr} src={appData.darkLogo} alt='logo' />
            ) : (
              <img ref={lr} src={appData.lightLogo} alt='logo' />
            )
          ) : (
            <img ref={lr} src={appData.lightLogo} alt='logo' />
          )}
        </Link>

        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          onClick={handleMobileDropdown}
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='icon-bar'>
            <i className='fas fa-bars'></i>
          </span>
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item'>
              <Link to='/#top' className='nav-link' state={{ ref: 'top' }}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/#features'
                className='nav-link'
                state={{ ref: 'features' }}
              >
                Tính Năng
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/#guide' className='nav-link' state={{ ref: 'guide' }}>
                Hướng Dẫn
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/#download'
                className='nav-link'
                state={{ ref: 'download' }}
              >
                Tải Ứng Dụng
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarApp;
