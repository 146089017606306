import Footer2 from 'components/Footer2/footer2';
import NavbarApp from 'components/Navbar-mobile-app/navbar-for-remove-acc-guide-page';
import RemoveAccountGuide from 'components/RemoveAccountGuide';
import LightTheme from 'layouts/Light';
import React from 'react';

const RemoveAccountGuidePage = () => {
  const navbarRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add('nav-scroll');
    } else {
      navbar.classList.remove('nav-scroll');
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add('nav-scroll');
      } else {
        navbar.classList.remove('nav-scroll');
      }
    });
  }, [navbarRef]);

  return (
    <LightTheme mobileappstyle>
      <NavbarApp nr={navbarRef} theme='themeL' />
      <RemoveAccountGuide />
      <Footer2 />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>UniService.Vn - Hướng dẫn xoá account</title>
    </>
  );
};

export default RemoveAccountGuidePage;
