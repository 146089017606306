import React from 'react';

const RemoveAccountGuide = () => {
  return (
    <section id='remove-account-guide' className='app-services section-padding'>
      <div className='container'>
        <div className='w-75 m-auto bg-gray p-4 rounded'>
          <div className='s-head'>
            <h6>Hướng dẫn xoá tài khoản</h6>

            <div className='mt-3'>
              <strong>Bước 1:</strong> Mở ứng dụng UniService trên đện thoại
              lên, sau đó đăng nhập vào tài khoản mà bạn muốn xóa.
            </div>

            <div className='mt-3'>
              <strong>Bước 2:</strong> Chuyển đến mục <strong>Tài khoản</strong>{' '}
              và nhấn chọn biểu tượng răng cưa để vào{' '}
              <strong>Thiết lập tài khoản</strong>.
            </div>

            <div className='img mt-3'>
              <img src='/img/remove-account/img1.png' alt='buoc-2' />
            </div>

            <div className='mt-3'>
              <strong>Bước 3:</strong> Tìm mục <strong>Xóa tài khoản</strong> ở
              dưới cùng và nhấn vào. Nhập <strong>Mã Pin</strong> để xác nhận.
            </div>

            <div className='img mt-3'>
              <img src='/img/remove-account/img2.png' alt='buoc-3' />
            </div>

            <div className='mt-3'>
              <strong>Bước 4:</strong> Xác nhận lại một lần nữa bằng cách nhấn{' '}
              <strong>Xoá tài khoản</strong>.
            </div>

            <div className='img mt-3'>
              <img src='/img/remove-account/img3.png' alt='buoc-4' />
            </div>

            <div className='mt-3'>
              <strong>Lưu ý:</strong>
              <ul id='circle'>
                <li>
                  Tài khoản đã bị xóa sẽ không thể đăng nhập vào và xem lại các
                  thông báo hay các đơn hàng trước đây
                </li>
                <li>
                  Tất cả số tiền trong tài khoản sẽ mất nếu bạn xoá tài khoản mà
                  vẫn còn số dư
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RemoveAccountGuide;
